import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';

const Documentation = ({ markdownUrl }) => {
  const [markdownContent, setMarkdownContent] = useState('');
  const [fetchAttempts, setFetchAttempts] = useState(0);

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(markdownUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setMarkdownContent(text);
      } catch (error) {
        console.error('Error fetching markdown:', error);
        if (fetchAttempts < 3) {  // Retry up to 3 times
          setFetchAttempts(fetchAttempts + 1);
          setTimeout(fetchMarkdown, 1000);  // Retry after 1 second
        } else {
          setMarkdownContent('Error loading documentation.');
        }
      }
    };

    fetchMarkdown();
  }, [markdownUrl, fetchAttempts]);

  return (
    <div className="documentation prose prose-lg max-w-none mx-auto p-4  rounded-lg shadow-md">
      <ReactMarkdown 
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeHighlight]}
        components={{
          img: ({ node, ...props }) => (
            <img
              className="max-w-full rounded-lg shadow-md"
              {...props}
              alt={props.alt || ''}
              onError={(e) => { e.target.style.display = 'none'; console.error(`Error loading image: ${e.target.src}`); }}
            />
          ),
          h1: ({ node, ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4 text-gray-900" {...props} />,
          h2: ({ node, ...props }) => <h2 className="text-3xl font-semibold mt-6 mb-4 text-gray-800" {...props} />,
          h3: ({ node, ...props }) => <h3 className="text-2xl font-semibold mt-4 mb-2 text-gray-700" {...props} />,
          p: ({ node, ...props }) => <p className="mt-2 mb-4 leading-relaxed text-gray-600" {...props} />,
          ul: ({ node, ...props }) => <ul className="list-disc list-inside ml-6 text-gray-600" {...props} />,
          ol: ({ node, ...props }) => <ol className="list-decimal list-inside ml-6 text-gray-600" {...props} />,
          blockquote: ({ node, ...props }) => <blockquote className="border-l-4 border-gray-400 pl-4 italic text-gray-500" {...props} />,
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <pre className={`rounded-lg p-4 bg-gray-800 text-white overflow-x-auto ${className}`} {...props}>
                <code>{children}</code>
              </pre>
            ) : (
              <code className={`bg-gray-100 p-1 rounded ${className}`} {...props}>
                {children}
              </code>
            );
          },
        }}
      >
        {markdownContent}
      </ReactMarkdown>
    </div>
  );
};

export default Documentation;
