import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  CircularProgress,
  Box,
} from "@mui/material";
import client from "../client";
import theme from "../theme";
import AddConfigModal from "../components/modals/configs/AddConfigModal";
import DeleteConfigModal from "../components/modals/configs/DeleteConfigModal";
import DeleteConfigFileModal from "../components/modals/configs/DeleteConfigFileModal";
import AddFileConfigModal from "../components/modals/configs/AddFileConfigModal";
import AddRecordModal from "../components/modals/configs/AddRecordModal";
import ViewListModal from "../components/common/ViewListModal";
import DeleteCustomListModal from "../components/modals/configs/DeleteCustomListModal";
import EditCustomListModal from "../components/modals/configs/EditCustomListModal";
import { ActionDotIcon } from "../assets/images/icons/ActionDotIcon/ActionDotIcon";
import SelectFilesButtonComponent from "../components/modals/configs/SelectFilesButtonComponent";
import SelectFilesListComponent from "../components/modals/configs/SelectFilesListComponent";
import { toast } from "react-toastify";

function ConfigsPage() {
  const [configs, setConfigs] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(false);
  const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isDeleteCustomListModalOpen, setIsDeleteCustomListModalOpen] = useState(false);
  const [isEditCustomListModalOpen, setIsEditCustomListModalOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [postModalAction, setPostModalAction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [fileNames, setFileNames] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileContent, setSelectedFileContent] = useState("");
  const [originalFileContent, setOriginalFileContent] = useState("");
  const [filesContent, setFilesContent] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [promptMessage, setPromptMessage] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [generatedPrompts, setGeneratedPrompts] = useState([]);
  const [selectedGeneratedPrompt, setSelectedGeneratedPrompt] = useState(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [originalPrompt, setOriginalPrompt] = useState(""); 
  const formRef = useRef(null);

  const fetchConfigs = async () => {
    setLoading(true);
    try {
      const response = await client.get("/v1/rails/configs");
      setConfigs(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUpdatedList = async () => {
    try {
      const response = await client.get("/custom_suite_list");
      return response.data;
    } catch (error) {
      toast.error("Error fetching custom suites");
      return [];
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  useEffect(() => {
    if (!isAddRecordModalOpen && postModalAction) {
      if (postModalAction.type === "view") {
        setIsViewModalOpen(true);
      } else if (postModalAction.type === "delete") {
        setIsDeleteCustomListModalOpen(true);
      } else if (postModalAction.type === "edit") {
        setIsEditCustomListModalOpen(true);
      }
      setPostModalAction(null);
    }
  }, [isAddRecordModalOpen, postModalAction]);

  const handleMenuClick = (event, config) => {
    setAnchorEl(event.currentTarget);
    setSelectedConfigId(config.id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    const config = configs.find((config) => config.id === selectedConfigId);
    setSelectedConfig(config);
    setSelectedTab(0);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    const config = configs.find((config) => config.id === selectedConfigId);
    setSelectedConfig(config);
    setIsDeleteModalOpen(true);
    handleMenuClose();
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleNewConfigAdded = async (newConfig) => {
    await fetchConfigs();
    setIsAddModalOpen(false);
    setSelectedConfig(newConfig);
    fetchFileContents(newConfig.id);
  };

  const fetchFileContents = async (configId) => {
    try {
      const response = await client.get(`/read_config?config_name=${configId}`);
      setFilesContent(response.data);
      const fileNames = Object.keys(response.data);
      setFileNames(fileNames);
      setSelectedFileName("");
      setSelectedFileContent("");
      setOriginalFileContent("");
    } catch (error) {
      console.error("Error fetching file contents:", error);
      toast.error("Error fetching file contents");
    }
  };

  useEffect(() => {
    if (selectedConfig) {
      fetchFileContents(selectedConfig.id);
    } else {
      setFileNames([]);
      setSelectedFileName("");
      setSelectedFileContent("");
      setOriginalFileContent("");
    }
  }, [selectedConfig]);

  const selectFile = (fileName) => {
    setSelectedFileName(fileName);
    setSelectedFileContent(fileName ? filesContent[fileName] : "");
    setOriginalFileContent(fileName ? filesContent[fileName] : "");
  };

  const saveEditedContent = async () => {
    const updateConfigEndpoint = `/edit_config?config_name=${
      selectedConfig.id
    }&file_name=${selectedFileName}&content=${encodeURIComponent(
      selectedFileContent
    )}`;
    try {
      await client.post(updateConfigEndpoint);
      toast.success("File content updated successfully!");
      setFilesContent((prev) => ({
        ...prev,
        [selectedFileName]: selectedFileContent,
      }));
      setOriginalFileContent(selectedFileContent);
    } catch (error) {
      console.error("Error saving file content:", error);
      toast.error(
        "Error saving file content: " +
          (error.response?.data?.detail || error.message)
      );
    }
  };

  const deleteSelectedFile = () => {
    setIsDeleteFileModalOpen(true);
  };

  const addFilesToKnowledgeBase = async () => {
    if (!selectedConfig) {
      toast.error("Please select a Policy");
      return;
    }
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      formData.append("config_name", selectedConfig ? selectedConfig.id : "");
      try {
        await client.post("/update_kb", formData);
        toast.success("Knowledge base was updated!");
        setSelectedFiles([]);
        if (selectedConfig) {
          await fetchFileContents(selectedConfig.id);
        }
      } catch (error) {
        console.error("Error updating knowledge base:", error);
        toast.error("Error updating knowledge base");
      }
    }
  };

  const handleSubmit = async () => {
    if (!selectedConfig) {
      toast.error("Select a Policy");
      return;
    }
    if (!promptMessage.trim()) {
      toast.error("Write your prompt to get response");
      return;
    }

    setApiResponse("");
    setIsSubmitting(true);

    try {
      const response = await client.post("/v1/chat/completions", {
        config_id: selectedConfig.id,
        messages: [{ role: "user", content: promptMessage }],
        stream: false,
      });
      const content = response.data.messages[0].content;
      setApiResponse(content);
      toast.success("Prompt submitted successfully!");
    } catch (error) {
      console.error("Failed to submit prompt:", error);
      toast.error("Failed to submit prompt: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGenerateClick = async () => {
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt to generate variations");
      return;
    }
  
    setIsGenerating(true);
    setOriginalPrompt(promptMessage); 
    setGeneratedPrompts([]); 
  
    try {
      const response = await client.post(`/generate_prompt?user_prompt=${encodeURIComponent(promptMessage)}`);
      setGeneratedPrompts(response.data.variations);
      setSelectedGeneratedPrompt(null);
      toast.success("Prompt variations generated successfully!");
    } catch (error) {
      console.error("Failed to generate prompt variations:", error);
      toast.error("Failed to generate prompt variations: " + error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGeneratedPromptClick = (index) => {
    if (selectedGeneratedPrompt === index) {
      setSelectedGeneratedPrompt(null); 
      setPromptMessage(originalPrompt); 
    } else {
      setSelectedGeneratedPrompt(index);
      setPromptMessage(generatedPrompts[index]);
    }
  };

  const handleClearResponse = () => {
    setApiResponse("");
  };

  const renderFileSelector = () => {
    if (fileNames.length < 3) {
      return (
        <div style={{ display: "flex", overflowX: "auto" }}>
          {fileNames.map((fileName, index) => (
            <button
              key={index}
              onClick={() => selectFile(fileName)}
              style={{
                background:
                  selectedFileName === fileName
                    ? theme.tmryk_background_color
                    : "white",
                color: selectedFileName === fileName ? "white" : "black",
                fontWeight: selectedFileName === fileName ? "bold" : "normal",
                margin: "0 5px",
                padding: "5px 5px",
                border: "1px solid gray",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {getFileLabel(fileName)}
            </button>
          ))}
        </div>
      );
    } else {
      return (
        <select
          className="bg-gray-50 border border-gray-300 rounded-md text-gray-700 leading-tight focus:outline-none focus:border-[#031A58]"
          style={{ padding: "5px 5px" }}
          value={selectedFileName}
          onChange={(e) => selectFile(e.target.value)}
        >
          <option value="">Select a File</option>
          {fileNames.map((fileName, index) => (
            <option key={index} value={fileName}>
              {getFileLabel(fileName)}
            </option>
          ))}
        </select>
      );
    }
  };

  const getFileLabel = (fileName) => {
    if (fileName.endsWith(".co")) {
      return `Colang (${fileName.replace(".co", "")})`;
    } else if (fileName.endsWith(".yml") || fileName.endsWith(".yaml")) {
      return `Configuration (${fileName.replace(/\.(yml|yaml)$/, "")})`;
    } else {
      return fileName;
    }
  };

  const renderEditConfigTab = () => (
    <div className="flex flex-col w-full pr-8">
      {selectedConfig && (
        <div className="flex mt-4">
          <h3
            className={`text-lg font-medium text-[${theme.tmryk_black_text_color}]`}
          >
            Current Selected Policy: {selectedConfig.id}
          </h3>
        </div>
      )}
      <div className="flex mt-4 mb-2">
        <p className={`text-xs text-[${theme.tmryk_black_text_color}]`}>
          Click on a checkbox to select a Policy from the Policy
          <br />
          Select the Colang or configuration settings for the models.
          <br />
          Once you make the changes, you can save the configuration and then
          test it using the Test Policy Panel
        </p>
      </div>
      <div className="w-full mt-2 mb-4 border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
        <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600">
          <div className="flex items-center">
            <span>Edit</span>
          </div>
          <div className="flex items-center">
            {renderFileSelector()}
            <Button
              variant="contained"
              sx={{
                backgroundColor: selectedConfig
                  ? theme.tmryk_background_color
                  : "grey",
                color: "white",
                marginLeft: "10px",
                "&:hover": { backgroundColor: "#172E6C" },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  color: "rgba(0, 0, 0, 0.26)",
                  fontWeight: "bold",
                },
              }}
              onClick={() => setIsAddFileModalOpen(true)}
              disabled={!selectedConfig}
            >
              Add File
            </Button>
          </div>
        </div>
        <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800 flex flex-col">
          <textarea
            rows="10"
            className="block w-full mb-4 text-sm text-gray-800 bg-white border-0 dark:bg-black-800 focus:outline-none dark:text-black dark:placeholder-gray-400"
            placeholder="Select a file to edit"
            value={selectedFileContent}
            onChange={(e) => setSelectedFileContent(e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              sx={{
                backgroundColor: selectedFileName
                  ? theme.tmryk_background_color
                  : "grey",
                color: "white",
                "&:hover": { backgroundColor: "#172E6C" },
                marginLeft: "10px",
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  color: "rgba(0, 0, 0, 0.26)",
                  fontWeight: "bold",
                },
              }}
              onClick={saveEditedContent}
              disabled={!selectedFileName}
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: selectedFileName
                  ? theme.tmryk_background_color
                  : "grey",
                color: "white",
                "&:hover": { backgroundColor: "#172E6C" },
                marginLeft: "10px",
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  color: "rgba(0, 0, 0, 0.26)",
                  fontWeight: "bold",
                },
              }}
              onClick={deleteSelectedFile}
              disabled={
                !selectedFileName ||
                selectedFileName === "config.yaml" ||
                selectedFileName === "config.yml"
              }
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <div className="flex">
        <label className={`font-semibold text-[${theme.tmryk_black_text_color}]`}>
          Select Knowledge Base Files to Upload
        </label>
      </div>
      <div className="flex mt-2 mb=4">
        <p className="text-xs text-gray-500">
          Note: Upload Markdown or text files only. Each file should not exceed
          5MB.
        </p>
      </div>
      <SelectFilesButtonComponent
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        ref={formRef}
      />
      <SelectFilesListComponent selectedFiles={selectedFiles} />
      <div className="flex">
        <Button
          variant="contained"
          style={{
            backgroundColor:
              selectedFiles.length > 0
                ? theme.tmryk_background_color
                : "rgba(0, 0, 0, 0.12)",
            color: selectedFiles.length > 0
                ? "white"
                : "rgba(0, 0, 0, 0.26)",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: selectedFiles.length > 0 ? "#172E6C" : "grey",
            },
            "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  color: "rgba(0, 0, 0, 0.26)",
                  fontWeight: "bold",
                },
          }}
          onClick={addFilesToKnowledgeBase}
          disabled={selectedFiles.length === 0}
        >
          Upload
        </Button>
      </div>
    </div>
  );

  const renderTestConfigTab = () => (
    <div className="flex flex-col w-full pr-8">
      {selectedConfig && (
        <div className="flex mt-4 mb-2">
          <p className={`text-lg font-medium text-[${theme.tmryk_black_text_color}]`}>
            Current Selected Policy: {selectedConfig.id}
          </p>
        </div>
      )}
      <div className="flex mb-4 mt-4">
        <p className={`text-xs text-[${theme.tmryk_black_text_color}]`}>
          Try out the Policy after your changes have been saved.
        </p>
      </div>
      <div className="w-full mb-2 border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
        <div
          className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
          style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
        >
          <div className="flex items-center">
            <span>Prompt</span>
          </div>
          
          {generatedPrompts.length > 0 && (
            <div 
              style={{ 
                display: "flex", 
                flexWrap: "wrap",  
                justifyContent: "flex-start", 
                gap: "10px", 
              }}
            >
              {generatedPrompts.map((_, index) => (
                <Button
                  key={index}
                  variant="contained"
                  style={{
                    backgroundColor: selectedGeneratedPrompt === index ? theme.tmryk_background_color : "white",
                    color: selectedGeneratedPrompt === index ? "white" : theme.tmryk_background_color,
                    border: selectedGeneratedPrompt === index ? "1px solid white" : "none",
                    padding: "6px 16px",
                    minWidth: "100px",
                  }}
                  onClick={() => handleGeneratedPromptClick(index)}
                >
                  Prompt {index + 1}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800 flex flex-col">
          <textarea
            rows="4"
            className="block w-full text-sm mb-2 text-gray-800 bg-white border-0 dark:bg-black-800 dark:text-black dark:placeholder-gray-400 focus:outline-none"
            placeholder="Write your prompt here and click on submit"
            value={promptMessage}
            onChange={(e) => setPromptMessage(e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              style={{
                backgroundColor: promptMessage.trim()
                  ? theme.tmryk_background_color
                  : "rgba(0, 0, 0, 0.12)",  // Same background as disabled Record button
                color: promptMessage.trim()
                  ? "white"
                  : "rgba(0, 0, 0, 0.26)",  // Same color as disabled Record button
                fontWeight: "bold",
                marginRight: "10px",
              }}
              onClick={handleGenerateClick}
              disabled={!promptMessage.trim() || isGenerating}
            >
              {isGenerating ? <CircularProgress size={24} color="inherit" /> : "Generate"}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full mt-4 border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
        <div
          className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
          style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
        >
          <div className="flex items-center">
            <span>Response</span>
          </div>
        </div>
        <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800">
          <textarea
            rows="10"
            className="block w-full px-0 text-sm mb-2 text-white-800 bg-white border-0 dark:bg-black-800 focus:outline-none dark:text-black dark:placeholder-gray-400"
            placeholder="The generated response will appear here once you click submit"
            value={apiResponse}
            readOnly
          />
          <div className="flex justify-end">
            <Button
              variant="contained"
              style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
              onClick={handleClearResponse}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: apiResponse ? theme.tmryk_background_color : "rgba(0, 0, 0, 0.12)",
                color: apiResponse ? "white" : "rgba(0, 0, 0, 0.26)",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => setIsAddRecordModalOpen(true)}
              disabled={!apiResponse}
            >
              Record
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const handleCheckboxChange = (config) => {
    setSelectedConfig(
      selectedConfig && selectedConfig.id === config.id ? null : config
    );
    handleClearResponse();
    setGeneratedPrompts([]); 
  };

  return (
    <div className="flex h-full w-full">
      <div
        style={{ width: "120vw", padding: "6px", marginTop: "180px", margin: "auto" }}
        className="h-full"
      >
        <div className="flex justify-between p-4 items-center mb-4">
          <div>
            <div
              className="text-2xl font-semibold"
              style={{ color: theme.tmryk_background_color }}
            >
              Policy Management
            </div>
            <div className="text-sm" style={{ color: theme.tmryk_black_text_color }}>
              Manage policy settings
            </div>
          </div>
          <div className="flex">
            <Button
              variant="contained"
              style={{
                backgroundColor: theme.tmryk_background_color,
                color: "white",
                width: "195px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
              onClick={() => setIsAddModalOpen(true)}
            >
              Add / Update Policy
            </Button>
          </div>
        </div>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress />
          </Box>
        ) : (
          <div style={{ display: "flex", maxHeight: "calc(100% - 100px)", overflowY: "auto" }}>
            <div
              className="w-[47.35rem]"
              style={{ maxHeight: "750px", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    style={{
                      height: "42px",
                      maxWidth: "47.35rem",
                      backgroundColor: theme.tmryk_background_color,
                    }}
                  >
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 16px 6px 0",
                        borderBottom: "none",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": { color: "white" },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 32px 6px 0",
                        borderBottom: "none",
                      }}
                    >
                      Policy ID
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        padding: "6px 6px 6px 0",
                        borderBottom: "none",
                      }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {configs.map((config, index) => (
                    <TableRow
                      key={index}
                      style={{
                        height: "54px",
                        maxWidth: "600px",
                        backgroundColor:
                          selectedConfig && selectedConfig.id === config.id
                            ? theme.tmryk_background_color
                            : index % 2 === 0
                            ? "white"
                            : "white",
                        color:
                          selectedConfig && selectedConfig.id === config.id
                            ? "white"
                            : "white",
                      }}
                    >
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 16px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        <Checkbox
                          sx={{
                            color:
                              selectedConfig && selectedConfig.id === config.id
                                ? "white"
                                : theme.tmryk_background_color,
                            "&.Mui-checked": { color: "white" },
                          }}
                          checked={
                            selectedConfig
                              ? selectedConfig.id === config.id
                              : false
                          }
                          onChange={() => handleCheckboxChange(config)}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 32px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        {config.id}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            selectedConfig && selectedConfig.id === config.id
                              ? "white"
                              : theme.tmryk_black_text_color,
                          padding: "6px 6px 6px 0",
                          borderBottom: "none",
                        }}
                      >
                        <Button onClick={(event) => handleMenuClick(event, config)}>
                          <ActionDotIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "top", horizontal: "right" }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiPaper-root": {
                              boxShadow: `0px 2px 8px ${theme.tmryk_box_shadow}`,
                              backgroundColor: "white",
                            },
                          }}
                        >
                          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                          <MenuItem
                            onClick={handleDeleteClick}
                            sx={{
                              color: "red",
                              "&:hover": {
                                backgroundColor: "#FDECEA",
                                color: "red",
                              },
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div
              style={{ width: "2px", backgroundColor: "#ccc", margin: "0 16px" }}
            ></div>
            <div
              className="w-full"
              style={{ maxHeight: "100%", overflowY: "auto", overflowX: "auto" }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: { backgroundColor: theme.tmryk_background_color },
                }}
              >
                <Tab
                  label="Edit Policy"
                  style={{
                    color:
                      selectedTab === 0
                        ? "white"
                        : theme.tmryk_background_color,
                    backgroundColor:
                      selectedTab === 0 ? theme.tmryk_background_color : "inherit",
                    borderRadius: selectedTab === 0 ? "8px 8px 0 0" : "8px",
                  }}
                />
                <Tab
                  label="Test Policy"
                  style={{
                    color:
                      selectedTab === 1
                        ? "white"
                        : theme.tmryk_background_color,
                    backgroundColor:
                      selectedTab === 1 ? theme.tmryk_background_color : "inherit",
                    borderRadius: selectedTab === 1 ? "8px 8px 0 0" : "8px",
                  }}
                />
              </Tabs>
              <div style={{ display: selectedTab === 0 ? "block" : "none" }}>
                {renderEditConfigTab()}
              </div>
              <div style={{ display: selectedTab === 1 ? "block" : "none" }}>
                {renderTestConfigTab()}
              </div>
            </div>
          </div>
        )}
      </div>

      {isAddModalOpen && (
        <AddConfigModal
          isModalOpen={isAddModalOpen}
          setIsModalOpen={setIsAddModalOpen}
          onConfigAdded={handleNewConfigAdded}
          selectedConfig={selectedConfig}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfigModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          selectedConfig={selectedConfig}
          onSuccess={async () => {
            toast.success("Policy deleted successfully!");
            setSelectedConfig(null);
            await fetchConfigs();
          }}
        />
      )}
      {isDeleteFileModalOpen && (
        <DeleteConfigFileModal
          isModalOpen={isDeleteFileModalOpen}
          setIsModalOpen={setIsDeleteFileModalOpen}
          configName={selectedConfig?.id}
          fileName={selectedFileName}
          onSuccess={() => {
            setFilesContent((prev) => {
              const newContent = { ...prev };
              delete newContent[selectedFileName];
              return newContent;
            });
            setFileNames((prev) => prev.filter((name) => name !== selectedFileName));
            setSelectedFileName("");
            setSelectedFileContent("");
            toast.success("File deleted successfully!");
          }}
        />
      )}
      {isAddFileModalOpen && (
        <AddFileConfigModal
          isModalOpen={isAddFileModalOpen}
          setIsModalOpen={setIsAddFileModalOpen}
          configName={selectedConfig?.id}
          onSuccess={async () => {
            toast.success("Files added successfully!");
            await fetchFileContents(selectedConfig.id);
          }}
        />
      )}
      {isAddRecordModalOpen && (
        <AddRecordModal
          isModalOpen={isAddRecordModalOpen}
          setIsModalOpen={setIsAddRecordModalOpen}
          promptMessage={promptMessage}
          apiResponse={apiResponse}
          openViewModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "view" });
          }}
          openDeleteModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "delete" });
          }}
          openEditModal={(listName) => {
            setSelectedList(listName);
            setPostModalAction({ type: "edit" });
          }}
        />
      )}
      {isViewModalOpen && (
        <ViewListModal
          isOpen={isViewModalOpen}
          setIsOpen={setIsViewModalOpen}
          listName={selectedList}
          setIsModalOpen={setIsAddRecordModalOpen}
        />
      )}
      {isDeleteCustomListModalOpen && (
        <DeleteCustomListModal
          isOpen={isDeleteCustomListModalOpen}
          setIsOpen={setIsDeleteCustomListModalOpen}
          listName={selectedList}
          onDeleteSuccess={() => {
            setSelectedList(null);
            toast.success("Custom list deleted successfully!");
          }}
          setParentIsOpen={setIsAddRecordModalOpen}
        />
      )}
      {isEditCustomListModalOpen && (
        <EditCustomListModal
          isOpen={isEditCustomListModalOpen}
          setIsOpen={setIsEditCustomListModalOpen}
          listName={selectedList}
          setIsModalOpen={setIsAddRecordModalOpen}
          fetchUpdatedList={fetchUpdatedList}
        />
      )}
    </div>
  );
}

export default ConfigsPage;
