import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import client from '../../../client';
import theme from '../../../theme'
import config from '../../../config';
import { Button, RadioGroup, FormControlLabel, Radio, TextField, Alert, FormControl, InputLabel, Select, MenuItem, CircularProgress, Checkbox, Box } from '@mui/material';

const LLM_TYPES = {
  openai: {
    keyName: 'openai_api_key',
    models: ['gpt-4o', 'gpt-4-turbo', 'gpt-4', 'gpt-3.5-turbo-instruct', 'gpt-3.5-turbo'],
  },
  huggingface_hub: {
    keyName: 'huggingfacehub_api_token',
    models: ['meta-llama/Meta-Llama-3-8B-Instruct', 'mistralai/Mixtral-8x22B-v0.1', 'mistralai/Mistral-7B-v0.3', 'google/gemma-2-9b-it', 'Qwen/Qwen2-72B-Instruct'],
  },
  bedrock_claude: {
    keyName: '',
    models: ['anthropic.claude-3-5-sonnet'], 
  },
};

export default function AddConfigModal({ isModalOpen, setIsModalOpen, onConfigAdded, selectedConfig }) {
  const formRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [creationType, setCreationType] = useState('basic');
  const [configName, setConfigName] = useState('');
  const [engine, setEngine] = useState('');
  const [model, setModel] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [selectedConfigToUpdate, setSelectedConfigToUpdate] = useState('');
  const [jailbreakDetection, setJailbreakDetection] = useState(false);
  const [moderation, setModeration] = useState(false);
  const [hallucination, setHallucination] = useState(false);

  useEffect(() => {
    client.get("/user_info")
      .then(response => {
        const data = response.data;
        setTenantId(data.tenantId);
        setIsSuperuser(data.is_superuser);
      })
      .catch(error => {
        setError('Failed to fetch user info');
      });

    client.get("/v1/rails/configs")
      .then(response => {
        setConfigs(response.data);
      })
      .catch(error => {
        setError('Failed to fetch configurations');
      });
  }, []);

  useEffect(() => {
    setValidationError('');
    setApiKey(''); // Reset API key when creation type changes
  }, [creationType]);

  useEffect(() => {
    if (selectedConfig) {
      setCreationType('update');
      setSelectedConfigToUpdate(selectedConfig.id);
      setConfigName(selectedConfig.id); // Set config name for update
      setEngine(selectedConfig.engine);
      setModel(selectedConfig.model);
      setApiKey(''); // Reset API key for update
      setJailbreakDetection(selectedConfig.jailbreakDetection);
      setModeration(selectedConfig.moderation);
      setHallucination(selectedConfig.hallucination);
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (creationType !== 'update') {
      setConfigName('');
      setApiKey(''); // Reset API key when not updating
    }
  }, [creationType]);

  const validateForm = () => {
    if (!configName && creationType !== 'update') return "Config name is required.";
    if (!engine) return "Engine is required.";
    if (!model) return "Model is required.";
    if (engine !== 'bedrock_claude' && !apiKey) return "API key is required."; // Skip API key check for bedrock_claude
    if (creationType === 'advanced' && selectedFiles.length === 0) return "At least one file must be selected.";
    return '';
  };

  const setApiKeyEndpoint = async (configId) => {
    const keyName = LLM_TYPES[engine].keyName;
    const setTokenEndpoint = `/llm_api_key?config_id=${configId}&llm_api_key_name=${keyName}&llm_api_key_value=${apiKey}`;
    return await client.post(setTokenEndpoint);
  };

  const updateConfig = async (configData, updateConfigEndpoint, configId) => {
    try {
      const updateConfigResponse = await client.post(updateConfigEndpoint, configData);
      if (updateConfigResponse.status === 200) {
        const setTokenResponse = await setApiKeyEndpoint(configId);
        if (setTokenResponse.status === 200) {
          const updatedConfig = {
            id: configId,
            engine,
            model,
            apiKey,
            jailbreakDetection,
            moderation,
            hallucination,
          };
          onConfigAdded(updatedConfig);
          setIsModalOpen(false);
        } else {
          setError(setTokenResponse.data.message || 'Failed to set API key');
        }
      } else {
        setError(updateConfigResponse.data.error || 'Failed to update Policy');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.detail || 'Failed to update Policy');
      } else {
        setError('Failed to update Policy');
      }
    } finally {
      setLoading(false);
    }
  };

  const createConfig = async (configData, createConfigEndpoint) => {
    try {
      const createConfigResponse = await client.post(createConfigEndpoint, configData);
  
      if (createConfigResponse.status === 200) {
        const configId = configName; // Use configName for creation
        const setTokenResponse = await setApiKeyEndpoint(configId);
        if (setTokenResponse.status === 200) {
          const newConfig = {
            id: configId,
            engine,
            model,
            apiKey,
            jailbreakDetection,
            moderation,
            hallucination,
          };
          onConfigAdded(newConfig);
          setIsModalOpen(false);
        } else {
          setError(setTokenResponse.data.message || 'Failed to set API key');
        }
      } else {
        setError(createConfigResponse.data.error || 'Failed to create Policy');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.detail || 'Failed to create Policy');
      } else {
        setError('Failed to create Policy');
      }
    } finally {
      setLoading(false);
    }
  };

  const createNewConfig = async () => {
    const validationMessage = validateForm();
    if (validationMessage) {
      setValidationError(validationMessage);
      return;
    }
  
    setLoading(true);
  
    try {
      const configId = creationType === 'update' ? selectedConfigToUpdate : configName;
  
      // Set engine and model logic
      let configData = `models:\n  - type: main\n    engine: ${engine === 'bedrock_claude' ? 'custom_llm_bedrock_claude' : engine}`;
      
      // Add model if engine is not bedrock_claude
      if (engine !== 'bedrock_claude') {
        configData += `\n    model: ${model}`;
      }
  
      if (jailbreakDetection) {
        configData += `\n\nrails:\n  config:\n    jailbreak_detection:\n      server_endpoint: "${config.JAILBREAK_DETECTION_URL}"\n      lp_threshold: 89.79\n      ps_ppl_threshold: 1845.65\n\n  input:\n    flows:\n      - jailbreak detection heuristics`;
      } else if (moderation) {
        configData += `\n  - type: llama_guard\n    engine: vllm_openai\n    parameters:\n      openai_api_base: "${config.MODERATION_URL}"\n      model_name: "meta-llama/LlamaGuard-7b"\nrails:\n  input:\n    flows:\n      - llama guard check input\n  output:\n    flows:\n      - llama guard check output`;
      } else if (hallucination) {
        configData += `\n\nrails:\n  config:\n    fact_checking:\n      parameters:\n        endpoint: "${config.HALLUCINATION_URL}"\n  output:\n    flows:\n      - alignscore check facts`;
      }
  
      const configDataPayload = {
        tenant_id: isSuperuser ? tenantId : '',
        "config.yml": configData,
      };
  
      if (creationType === 'advanced') {
        const formData = new FormData(formRef.current);
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append('files', selectedFiles[i]);
        }
        formData.append('new_config_name', configName);
        formData.append('config_data', JSON.stringify(configDataPayload));
        await createConfig(formData, "/create_config_with_files");
      } else if (creationType === 'basic') {
        const createConfigEndpoint = `/create_config_with_yaml?config_name=${configId}`;
        await createConfig(configDataPayload, createConfigEndpoint);
      } else if (creationType === 'update') {
        const updateConfigEndpoint = `/edit_config?config_name=${configId}&file_name=config.yml&content=${encodeURIComponent(configData)}`;
        await updateConfig({ "config.yml": configData }, updateConfigEndpoint, configId);
      }
  
      // Skip setting the API key if the engine is bedrock_claude
      if (engine !== 'bedrock_claude') {
        const setTokenResponse = await setApiKeyEndpoint(configId);
        if (setTokenResponse.status !== 200) {
          throw new Error(setTokenResponse.data.message || 'Failed to set API key');
        }
      }
  
      const newConfig = {
        id: configId,
        engine,
        model,
        apiKey,
        jailbreakDetection,
        moderation,
        hallucination,
      };
  
      onConfigAdded(newConfig);
      setIsModalOpen(false);
  
    } catch (error) {
      setError(error.message || 'Failed to create policy');
    } finally {
      setLoading(false);
    }
  };
  

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
  };

  const removeFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
  };

  const modelOptions = engine ? LLM_TYPES[engine].models.map((model) => (
    <MenuItem key={model} value={model}>{model}</MenuItem>
  )) : [];

  const handleCheckboxChange = (setFunction) => (event) => {
    setJailbreakDetection(false);
    setModeration(false);
    setHallucination(false);

    setFunction(event.target.checked);
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setIsModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-5/6 flex-col overflow-y-scroll bg-white shadow-xl rounded-lg"
                  style={{ marginTop: '40px', marginRight: '15px' }}>
                  <div className="px-4 py-6 sm:px-6 bg-white rounded-t-lg">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium" style={{ color: theme.tmryk_background_color }}>{creationType === 'update' ? 'Update Policy' : 'Add New Policy'}</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-white hover:bg-[#031A58] focus:outline-none focus:ring-2 "
                          onClick={() => setIsModalOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {error && <Alert severity="error" className="mt-4">{error}</Alert>}
                    {validationError && <Alert severity="error" className="mt-4">{validationError}</Alert>}
                  </div>
                  <div className="relative flex-1 px-4 pb-6 sm:px-6">
                    <form ref={formRef} className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                      <RadioGroup value={creationType} onChange={(e) => setCreationType(e.target.value)} row>
                        <FormControlLabel
                          value="basic"
                          control={<Radio sx={{ '&.Mui-checked': { color: theme.tmryk_background_color } }} />}
                          label="Basic Creation"
                        />
                        <FormControlLabel
                          value="advanced"
                          control={<Radio sx={{ '&.Mui-checked': { color: theme.tmryk_background_color } }} />}
                          label="Advanced Creation"
                        />
                        <FormControlLabel
                          value="update"
                          control={<Radio sx={{ '&.Mui-checked': { color: theme.tmryk_background_color } }} />}
                          label="Update Policy"
                        />
                      </RadioGroup>
                      
                      {creationType === 'basic' && (
                        <>
                          <div className="space-y-4">
                            <TextField
                              label="New Policy Name"
                              name="new_config_name"
                              value={configName}
                              onChange={(e) => setConfigName(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                            {isSuperuser && (
                              <TextField
                                label="Tenant ID"
                                name="tenantId"
                                value={tenantId}
                                onChange={(e) => setTenantId(e.target.value)}
                                fullWidth
                                className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                            )}
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Engine</InputLabel>
                              <Select
                                name="engine"
                                label="Engine"
                                value={engine}
                                onChange={(e) => setEngine(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {Object.keys(LLM_TYPES).map((key) => (
                                  <MenuItem key={key} value={key}>{key}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Model</InputLabel>
                              <Select
                                name="model"
                                label="Model"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {modelOptions}
                              </Select>
                            </FormControl>
                            {engine !== 'bedrock_claude' && (
                            <TextField
                              label="API Key"
                              name="apiKey"
                              value={apiKey}
                              onChange={(e) => setApiKey(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                            )}
                          </div>
                        </>
                      )}
                      
                      {creationType === 'advanced' && (
                        <>
                          <div className="space-y-4">
                            <TextField
                              label="New Policy Name"
                              name="new_config_name"
                              value={configName}
                              onChange={(e) => setConfigName(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                            {isSuperuser && (
                              <TextField
                                label="Tenant ID"
                                name="tenantId"
                                value={tenantId}
                                onChange={(e) => setTenantId(e.target.value)}
                                fullWidth
                                className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                              />
                            )}
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Engine</InputLabel>
                              <Select
                                name="engine"
                                label="Engine"
                                value={engine}
                                onChange={(e) => setEngine(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {Object.keys(LLM_TYPES).map((key) => (
                                  <MenuItem key={key} value={key}>{key}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Model</InputLabel>
                              <Select
                                name="model"
                                label="Model"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {modelOptions}
                              </Select>
                            </FormControl>
                            {engine !== 'bedrock_claude' && (
                            <TextField
                              label="API Key"
                              name="apiKey"
                              value={apiKey}
                              onChange={(e) => setApiKey(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                          )}
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={jailbreakDetection}
                                    onChange={handleCheckboxChange(setJailbreakDetection)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Jailbreak Detection"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={moderation}
                                    onChange={handleCheckboxChange(setModeration)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Moderation"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hallucination}
                                    onChange={handleCheckboxChange(setHallucination)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Hallucination"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                          </div>
                          <div className="mt-2 flex justify-start">
                            <Button
                              variant="contained"
                              onClick={() => fileInputRef.current.click()}
                              style={{ backgroundColor: theme.tmryk_background_color, color: "white" }}
                            >
                              Add Files
                            </Button>
                            <input
                              ref={fileInputRef}
                              type="file"
                              multiple
                              className="hidden"
                              onChange={handleFileChange}
                            />
                            </div>
                            <div className="mt-2">
                              {selectedFiles.map(file => (
                                <div key={file.name} className="flex items-center justify-between mt-2">
                                  <span>{file.name}</span>
                                  <button
                                    onClick={() => removeFile(file.name)}
                                    className="bg-red-500 text-white rounded-full px-2 py-1 ml-2"
                                  >
                                    -
                                  </button>
                                </div>
                              ))}
                            </div>
                        </>
                      )}
                      
                      {creationType === 'update' && (
                        <>
                          <div className="space-y-4">
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Select Policy to Update</InputLabel>
                              <Select
                                name="selectedConfigToUpdate"
                                label="Select Policy to Update"
                                value={selectedConfigToUpdate}
                                onChange={(e) => {
                                  const config = configs.find(c => c.id === e.target.value);
                                  setSelectedConfigToUpdate(e.target.value);
                                  if (config) {
                                    setConfigName(config.id); // Set config name for update
                                    setEngine(config.engine);
                                    setModel(config.model);
                                    setApiKey(''); // Reset API key for update
                                    setJailbreakDetection(config.jailbreakDetection);
                                    setModeration(config.moderation);
                                    setHallucination(config.hallucination);
                                  }
                                }}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {configs.map((config) => (
                                  <MenuItem key={config.id} value={config.id}>{config.id}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Engine</InputLabel>
                              <Select
                                name="engine"
                                label="Engine"
                                value={engine}
                                onChange={(e) => setEngine(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {Object.keys(LLM_TYPES).map((key) => (
                                  <MenuItem key={key} value={key}>{key}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel className="text-gray-700">Model</InputLabel>
                              <Select
                                name="model"
                                label="Model"
                                value={model}
                                onChange={(e) => setModel(e.target.value)}
                                className="w-full bg-white rounded text-gray-900 focus:outline-none focus:ring-green-500"
                              >
                                {modelOptions}
                              </Select>
                            </FormControl>
                            <TextField
                              label="API Key"
                              name="apiKey"
                              value={apiKey}
                              onChange={(e) => setApiKey(e.target.value)}
                              fullWidth
                              className="w-full bg-white border-2 rounded p-3 text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500"
                            />
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={jailbreakDetection}
                                    onChange={handleCheckboxChange(setJailbreakDetection)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Jailbreak Detection"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={moderation}
                                    onChange={handleCheckboxChange(setModeration)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Moderation"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={hallucination}
                                    onChange={handleCheckboxChange(setHallucination)}
                                    sx={{
                                      color: theme.tmryk_background_color,
                                      '&.Mui-checked': { color: theme.tmryk_background_color },
                                    }}
                                  />
                                }
                                label="Hallucination"
                                sx={{ justifyContent: 'flex-start', width: '100%' }}
                              />
                            </Box>
                          </div>
                        </>
                      )}
                      
                      <div className="mt-4 flex justify-end space-x-3">
                        <Button
                          variant="outlined"
                          onClick={() => setIsModalOpen(false)}
                          ref={cancelButtonRef}
                          style={{
                            borderColor: theme.tmryk_background_color,
                            color: theme.tmryk_background_color,
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                          }}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: theme.tmryk_background_color,
                            color: 'white',
                            width: '150px',
                            height: '48px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            boxShadow: '0 3px 5px 2px rgba(84, 130, 78, .3)',
                          }}
                          onClick={createNewConfig}
                          disabled={loading}
                        >
                          {loading ? <CircularProgress size={24} color="inherit" /> : (creationType === 'update' ? 'Update Policy' : 'Add Policy')}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
