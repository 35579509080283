import React, { useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import theme from "../../../theme";
import { toast } from "react-toastify";
import client from "../../../client";

const biasOptions = [
  "Race",
  "Color",
  "Religion",
  "Sex/gender",
  "Gender identity",
  "Sexual orientation",
  "Marital status",
  "Age",
];

const discriminationOptions = [
  "FCRA Discrimination",
  "EEOA Discrimination",
  "Manual Input",
];

export default function BiasDetectionModal({ config, llmData, llmType }) {
  const [selectedBias, setSelectedBias] = useState("");
  const [selectedDiscriminationType, setSelectedDiscriminationType] =
    useState("");
  const [promptMessage, setPromptMessage] = useState("");
  const [responses, setResponses] = useState({});
  const [variations, setVariations] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState("");
  const [score, setScore] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatedPrompts, setGeneratedPrompts] = useState([]);
  const [selectedGeneratedPrompt, setSelectedGeneratedPrompt] = useState(null);
  const [originalPrompt, setOriginalPrompt] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);

  const handleBiasChange = (event) => {
    setSelectedBias(event.target.value);
  };

  const handleDiscriminationTypeChange = (event) => {
    setSelectedDiscriminationType(event.target.value);
    setPromptMessage(""); // Clear prompt message on discrimination type change
  };

  const handleGenerateClick = async () => {
    if (!selectedBias || !selectedDiscriminationType) {
      setErrorOpen(true);
      return;
    }

    if (selectedDiscriminationType === "Manual Input") {
      setGeneratedPrompts([]);
      toast.info("Manual input selected, please enter your prompt manually.");
      return;
    }

    setIsGenerating(true);
    setOriginalPrompt(promptMessage);
    setGeneratedPrompts([]);
    setScore(""); // Reset score before generating prompts

    try {
      const response = await client.post(
        `/generate_discrimination_prompts?discrimination_type=${encodeURIComponent(
          selectedDiscriminationType
        )}&policy=${encodeURIComponent(config)}&bias_type=${encodeURIComponent(
          selectedBias
        )}&num_prompts=2&model=${encodeURIComponent(
          llmData.llmName
        )}&model_type=${encodeURIComponent(
          llmType
        )}&engine=${encodeURIComponent(
          llmData.llmModel
        )}&api_key=${encodeURIComponent(llmData.apiKey)}`
      );
      
      console.log(response.data);

      const generatedPromptKeys = Object.keys(response.data.responses);
      setGeneratedPrompts(generatedPromptKeys);
      setResponses(response.data.responses);
      setScore(response.data.overall_score || "N/A");
      setSelectedGeneratedPrompt(null);
      toast.success("Prompts generated successfully!");
    } catch (error) {
      console.error("Failed to generate prompts:", error);
      toast.error("Failed to generate prompts: " + error.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGeneratedPromptClick = (index) => {
    if (selectedGeneratedPrompt === index) {
      setSelectedGeneratedPrompt(null);
      setPromptMessage(originalPrompt);
    } else {
      setSelectedGeneratedPrompt(index);

      const selectedPrompt = generatedPrompts[index];
      setPromptMessage(selectedPrompt);

      const promptResponses = responses[selectedPrompt] || [];
      setVariations(promptResponses.map((entry) => entry.bias));

      if (promptResponses.length > 0) {
        setSelectedVariation(promptResponses[0].bias);
      } else {
        setSelectedVariation("");
      }
    }
  };

  const handleSubmit = async () => {
    if (!promptMessage.trim()) {
      toast.error("Please enter a prompt");
      return;
    }

    setResponses({});
    setScore("");
    setIsSubmitting(true);

    try {
      console.log(llmData)
      const response = await client.post(
        `/generate_prompt_with_bias?prompt=${encodeURIComponent(
          promptMessage
        )}&biasType=${encodeURIComponent(
          selectedBias
        )}&policy=${encodeURIComponent(config)}&model=${encodeURIComponent(
          llmData.llmName
        )}&model_type=${encodeURIComponent(
          llmType
        )}&engine=${encodeURIComponent(
          llmData.llmModel
        )}&api_key=${encodeURIComponent(llmData.apiKey)}`
      );
      

      const {
        responses: apiResponses,
        score: apiScore,
        variations: apiVariations,
      } = response.data;

      const responsesMap = {};
      apiVariations.forEach((variation) => {
        const matchingKey = Object.keys(apiResponses).find((key) =>
          variation.startsWith(key)
        );
        if (matchingKey) {
          responsesMap[variation] = apiResponses[matchingKey];
        } else {
          responsesMap[variation] = undefined;
        }
      });

      setResponses(responsesMap);
      setScore(apiScore);
      setVariations(apiVariations);
      setSelectedVariation(apiVariations[0]);
    } catch (error) {
      console.error("Failed to submit prompt:", error);
      toast.error("Failed to submit prompt: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleVariationChange = (event) => {
    setSelectedVariation(event.target.value);
  };

  return (
    <div className="m-2 w-full">
      {/* Row for two dropdowns */}
      <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="discrimination-type-select-label">
            Select Discrimination Type
          </InputLabel>
          <Select
            labelId="discrimination-type-select-label"
            id="discrimination-type-select"
            value={selectedDiscriminationType}
            onChange={handleDiscriminationTypeChange}
            label="Select Discrimination Type"
          >
            {discriminationOptions.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined">
          <InputLabel id="bias-select-label">Select Protected Class</InputLabel>
          <Select
            labelId="bias-select-label"
            id="bias-select"
            value={selectedBias}
            onChange={handleBiasChange}
            label="Select Protected Class"
          >
            {biasOptions.map((bias, index) => (
              <MenuItem key={index} value={bias}>
                {bias}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="flex w-full mb-2">
        {/* Prompt Section */}
        <div className="flex-grow border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600 mr-4">
          <div
            className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
            style={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
            }}
          >
            <Typography variant="body1">Prompt</Typography>
            {generatedPrompts.length > 0 && (
              <div style={{ display: "flex", gap: "10px" }}>
                {generatedPrompts.map((_, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    style={{
                      backgroundColor:
                        selectedGeneratedPrompt === index
                          ? theme.tmryk_background_color
                          : "white",
                      color:
                        selectedGeneratedPrompt === index
                          ? "white"
                          : theme.tmryk_background_color,
                      border:
                        selectedGeneratedPrompt === index
                          ? "1px solid white"
                          : "none",
                      padding: "6px 16px",
                      minWidth: "100px",
                    }}
                    onClick={() => handleGeneratedPromptClick(index)}
                  >
                    Prompt {index + 1}
                  </Button>
                ))}
              </div>
            )}
          </div>

          <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800">
            <TextField
              variant="outlined"
              multiline
              rows={3}
              placeholder="Write your prompt here and click on submit"
              value={promptMessage}
              onChange={(e) => setPromptMessage(e.target.value)}
              sx={{ "& fieldset": { border: "none" } }}
              fullWidth
            />
            <div className="flex justify-between mt-2">
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.tmryk_background_color,
                  color: "white",
                }}
                onClick={handleGenerateClick} // Button to generate prompts
                disabled={
                  isGenerating || !selectedBias || !selectedDiscriminationType
                }
              >
                {isGenerating ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Generate Prompts"
                )}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.tmryk_background_color,
                  color: "white",
                }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        </div>

        {/* Score Section */}
        <div className="w-1/4 border border-white-200 rounded-md bg-white dark:bg-white-700 dark:border-gray-600 mr-4">
          <div
            className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
            style={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
              borderBottom: "1px solid",
            }}
          >
            <Typography variant="body1">Score</Typography>
          </div>
          <div
            className="flex items-center justify-center py-4 bg-white rounded-b-md dark:bg-white-800"
            style={{
              height: "150px", // Increased height for better vertical space
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center", // Ensures the text is centered horizontally
            }}
          >
            <Typography variant="h4" component="div">
              {score || "N/A"}
            </Typography>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        {/* Response Section */}
        <div className="border border-white-200 rounded-md bg-gray-50 dark:bg-white-700 dark:border-gray-600">
          <div
            className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600"
            style={{
              backgroundColor: theme.tmryk_background_color,
              color: "white",
            }}
          >
            <Typography variant="body1">
              Response <br />
            </Typography>
            {variations.length > 0 && (
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="variation-select-label"
                  style={{ color: "white" }}
                >
                  Select Bias
                </InputLabel>
                <Select
                  labelId="variation-select-label"
                  id="variation-select"
                  value={selectedVariation}
                  onChange={handleVariationChange}
                  label="Select Bias"
                  sx={{
                    color: "white",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme.tmryk_background_color,
                        "& .MuiMenuItem-root": {
                          color: "white",
                        },
                      },
                    },
                  }}
                >
                  {variations.map((variation, index) => (
                    <MenuItem key={index} value={variation}>
                      {variation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>

          <div className="px-4 py-2 bg-white rounded-b-md dark:bg-white-800">
            <TextField
              variant="outlined"
              multiline
              rows={6}
              value={
                responses[selectedVariation] ||
                (selectedGeneratedPrompt !== null &&
                Object.keys(responses).length > 0
                  ? (
                      responses[
                        Object.keys(responses)[selectedGeneratedPrompt]
                      ] || []
                    ).find((response) => response.bias === selectedVariation)
                      ?.response || ""
                  : "")
              }
              fullWidth
              placeholder="The generated response will appear here"
              readOnly
              sx={{ "& fieldset": { border: "none" } }}
            />
          </div>
        </div>
      </div>

      {/* Error Dialog */}
      <Dialog
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle
          id="error-dialog-title"
          style={{ display: "flex", alignItems: "center" }}
        >
          <ErrorOutline color="error" style={{ marginRight: "10px" }} />
          Error
        </DialogTitle>
        <DialogContent>
          <Typography id="error-dialog-description">
            Please select a bias before generating prompt variations.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleErrorClose}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
